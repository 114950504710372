import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HorizontalBarChart from "./HorizontalBarChart";
import "./TicketInfo.css";
import Navbar from "../Navbar/Navbar";

const TicketInfo = () => {
  const [counter, setCounter] = useState(1);
  const [jsonData, setJsonData] = useState(null);
  const [jsonHeader, setJsonHeader] = useState(null);
  const [otherVersions, setOtherVersions] = useState([]);
  const [arrays, setArrays] = useState([]);
  const [objects, setObjects] = useState([]);
  const [variables, setVariables] = useState([]);
  const { ticketId } = useParams();
  console.log(ticketId);
  const file = ticketId + "-" + counter + ".json";
  const headerFile = ticketId + "-headers.json";
  const os = ["Android", "iOS", "watchOS", "iPadOS"];
  const severity = localStorage.getItem("severity") || "";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${file}`);
        const data = await response.json();
        setJsonData(data);
        const responseHeader = await fetch(headerFile);
        const headerData = await responseHeader.json();
        setJsonHeader(headerData);
        setOtherVersions(headerData.otherVersions);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }
    };
    fetchData();
  }, [counter]);

  const incrementCounter = () => {
    if (counter < 2) {
      setCounter(counter + 1);
    }
  };

  const decrementCounter = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };
  const headerObj = jsonHeader || [];
  const obj = jsonData || [];

  const device = obj ? obj.device : [];
  const deviceState = obj ? obj.deviceState : [];
  const activityTrail = obj ? obj.activityTrail : [];
  
  const functionParameters = obj? obj.functionParameters ? JSON.parse(obj.functionParameters):[] :  [];
  console.log(functionParameters);
  const networkParameterNoJson = obj ? obj.networkParameters : {};
  const networkParameters = networkParameterNoJson
    ? JSON.parse(networkParameterNoJson)
    : [];
  const stackTrace = obj ? obj.stacktrace : "";
  console.log(networkParameters);
  function formatEpochTime(epochTime) {
    const date = new Date(epochTime);

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const milliseconds = date.getMilliseconds().toString().padStart(3, "0");

    return `${hours}:${minutes}:${seconds}:${milliseconds}`;
  }
  function formatDate2(epochTime) {
    const date = new Date(epochTime);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const meridiem = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    return `${month} ${day}, ${year} ${hours}:${minutes} ${meridiem}`;
  }
  function formatDate1(epochTime) {
    const date = new Date(epochTime);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const milliseconds = date.getMilliseconds().toString().padStart(3, "0");
    return `${hours}:${minutes}:${seconds}:${milliseconds}`;
  }
  function formatDate3(epochTime) {
    const date = new Date(epochTime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const milliseconds = date.getMilliseconds().toString().padStart(3, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}:${milliseconds}`;
  }
  function getSeverityClass(severity) {
    if (severity == 0) {
      return "severity-0";
    } else if (severity == 1) {
      return "severity-1";
    } else if (severity == 2) {
      return "severity-2";
    }
  }

  const convertText = (text) => {
    if (typeof text !== "string") {
      return text;
    }
    const words = text.split(/(?=[A-Z])/);
    const formattedText = words.join(" ").toUpperCase();

    return formattedText;
  };
  function convertValue(text) {
    if (text == true) {
      return "YES";
    } else if (text == false) {
      return "NO";
    } else {
      return text;
    }
  }
  function getHint(hint) {
    switch (hint) {
      case 1:
        return "Since this is a custom issue, you are the best judge. You might find some indication in Activity Trail & Stack Trace.";
      case 2:
        return "This could be because of the values that went into the function. Check the function parameters.";
      case 3:
        return "This could be because of the values that went into the function. Check the function parameters.";
      case 4:
        return "This could be because of the values that went into the function. Check the function parameters.";
      case 5:
        return "This could be because of the values that went into the function or due to an external call. Check the function parameters and the stack trace.";
      case 6:
        return "Check the lifecycle events in Activity Trail.";
      case 7:
        return "The function call flow is broken. Check the function parameters and stack trace for hints.";
      case 8:
        return "This could be because of the values that went into the previous function or due to an external call. Check the function parameters and stack trace for hints.";
      case 9:
        return "Make sure the low memory warning is not triggered by this App. Check Activity Trail to confirm. If that looks fine, you may ignore this issue.";
      case 10:
        return "This could be because of the values that went into the function. Check the function parameters and the stack trace.";
      case 11:
        return "This is an uncaught exception. Check the stack trace.";
      case 12:
        return "This is an exception logged by the developer. Check the stack trace.";
      case 13:
        if (jsonData.networkResponseStatusCode === 404)
          return "Check the dynamic values in the URL.";
        if (jsonData.networkResponseStatusCode === 500)
          return "This could be a server side issue. Make sure you are sending the headers, path params, query params and request params as expected by the server";
        break; // ToDo
      case 14:
        return "This was an exception during the network call. Check the description and stack trace, if available.";
      case 15:
        return "This could very well be a server side issue if the affected devices count is high. Else you may ignore this.";
      case 16:
        return "Make sure the trim memory warning is not triggered by this App. Check Activity Trail to confirm. If that looks fine, you may ignore this issue.";
      case 17:
        return "Check Activity Trail to see where the free memory dips.";
      case 18:
        return "Same HTTP request with same payload was triggered in a span of 1 second thus unnecessarily clogging the bandwidth. Check the location of trigger in your code. ";
      case 19:
        return "UI Thread was blocked. Check the stack trace.";
      case 20:
        return "A spike in memory usage was reported. Check the free memory in Activity Trail for the hint.";
      case 21:
        if (headerObj.os === 0) {
          if (headerObj.sdk === 1)
            return "Check the object stack trace to know which object is leaking memory.";
          return "You should run the app in debug mode with our debug SDK and follow the Activity Trail. Finotes provides the object stack in debug mode.";
        } else {
          return "Follow Activity Trail and use XCode Leaks Instrument to pin down the leaking object.";
        }
      case 22:
        return "Check the stack trace of the NSError";
      case 23:
        return "Check the stack trace.";
      case 24:
        return "Make sure you are not doing heavy operations in UI Thread.";
      case 25:
        return "Make sure you are not doing heavy operations in UI Thread. Check the stack trace to know where the ANR was triggered.";
      case 26:
        return "Make sure you are not doing heavy operations in UI Thread. Check the stack trace to know where the App Hang was triggered.";
      case 28:
        return "Retrace the user activity based on Activity Trail and use the Flutter DevTool to identify the point of Memory Leak.";
      case 29:
        return "Check Activty Trail and the time taken";
      case 30:
        return "Check Activty Trail and the time taken";
      case 31:
        return "Check Activty Trail and the time taken";
      case 32:
        return "Check Activty Trail and the time taken";
      default:
        return "Oops ... We are unable to help you with a hint for this bug. Like humans, we also err at times.";
    }
  }
  function activityPercentage(maxMemory,totalMemory,freeMemory){
    return (((maxMemory-totalMemory+freeMemory)/maxMemory)*100).toFixed(2);
  }
  return (
    <div>
      <Navbar />
      <div className="ticketinfo-main">
        <div className="label-div">
          <div className={getSeverityClass(severity)}>&nbsp; ☉ &nbsp;</div>
          <div className="heading">{headerObj.description}</div>
          <div className="red-label">{(headerObj.sdk === 1 ? "debug": "")}</div>
          
        </div>
        {jsonHeader && (
          <div className="header-div">
            <div className="header-div-1">
              <HorizontalBarChart
                osSummary={headerObj.osSummary}
                deviceSummary={headerObj.deviceSummary}
                countrySummary={headerObj.countrySummary}
              />
              <div className="div-1-header">
                <p className="div-1-label">APP:</p>{" "}
                <p className="div-1-value">{headerObj.appName}</p>
              </div>
              <div className="div-1-header">
                <p className="div-1-label">PLATFORM:</p>{" "}
                <p className="div-1-value">{os[headerObj.os]}</p>
              </div>
              <div className="div-1-header">
                <p className="div-1-label">APP VERSION:</p>{" "}
                <p className="div-1-value">{headerObj.appVersion}</p>
              </div>
              <div className="div-1-header">
                <p className="div-1-label">LAST REPORTED:</p>{" "}
                <p className="div-1-value">
                  {formatDate2(headerObj.lastReportedTime)}
                </p>
              </div>
              <div className="div-1-header">
                <p className="div-1-label">FIRST REPORTED:</p>{" "}
                <p className="div-1-value">
                  {formatDate2(headerObj.ticketCreatedAt)}
                </p>
              </div>
              <div className="div-1-header">
                <p className="div-1-label">
                  AFFECTED DEVICES:<warn style={{ color: "blue" }}>ⓘ</warn>
                </p>{" "}
                <p className="div-1-value">{headerObj.deviceCount}</p>
              </div>
              <div className="div-1-header">
                <p className="div-1-label">OCCURRENCES:</p>{" "}
                <p className="div-1-value">{headerObj.occurrenceCount}</p>
              </div>
            </div>
            <div className="other-versions">
              <div className="other-version-label">
                ALSO REPORTED IN VERSIONS: &nbsp;
              </div>
              {otherVersions.map((item, index) => (
                <div className="version">{item}</div>
              ))}
            </div>
          </div>
        )}
        <div className="div-2-class">
          {counter === 2 && (
            <button onClick={decrementCounter}>⇚ Previous</button>
          )}
          <button onClick={incrementCounter} className="next-button">
            Next ⇛
          </button>
        </div>
        {jsonData && (
          <div key={obj.id} className="div-3">
            <div className="div-3-div-1 div-1-label">
              <p>{device.uniqueKey}</p>
              <p>{formatDate2(obj.createdAt)}</p>
            </div>
            <div className="div-3-div-2">
              <p>{obj.description}</p>
            </div>
            <div className="div-3-div-4 div-1-label coloured-component">
              <p>HINT</p>
            </div>
            <div className="div-3-div-2 coloured-component">
              <p>{getHint(headerObj.type)}</p>
            </div>
            <div className="div-3-div-5">
              <p className="div-3-div-5-p">
                Issue happened on {formatDate2(obj.createdAt)} 
              </p>
              {/*
              while calling{" "}
                {obj.networkUrl}{" "}
              <p className="div-3-div-5-p">
                {obj.networkUrl} was called at {formatEpochTime(obj.createdAt)}.
                Got '{obj.networkResponseStatusCode}' as response. and completed
                execution by {formatEpochTime(obj.createdAt)}
        </p>*/}
            </div>
            {networkParameters.length != 0 && (
              <div>
                <p className="div-3-div-6-p div-1-label">NETWORK PARAMETERS</p>
                <div className="div-3-div-6-table" style={{ padding: "20px" }}>
                  {Object.entries(networkParameters).map(([key, value]) => (
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <div className="column-head">{convertText(key)}</div>
                      <pre
                        className="column-body"
                        style={{
                          display: "flex",
                          margin: "0px",
                          marginBottom: "10px",
                        }}
                      >
                        {value}
                      </pre>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {functionParameters.length != 0 && (
              <div>
                <p className="div-3-div-6-p div-1-label">FUNCTION PARAMETERS</p>
                <div className="div-3-div-6-table" style={{ padding: "20px" }}>
                  {functionParameters.map((param, index) => (
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <div className="column-head">{param.name}</div>
                      <pre
                        className="column-body"
                        style={{
                          display: "flex",
                          margin: "0px",
                          marginBottom: "10px",
                        }}
                      >
                        {param.value}
                      </pre>
                      <pre
                        className="column-body"
                        style={{
                          display: "flex",
                          margin: "0px",
                          marginBottom: "10px",
                        }}
                      >
                        type: {param.type}
                      </pre>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {stackTrace != null ? (
              <div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="stack-div"
                >
                  <div className="div-3-div-6-p div-1-label">STACK TRACE</div>
                  <i>
                    <a
                      href="https://docs.finotes.com/restack.html"
                      target="_blank"
                      className="side-notes ng-binding"
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      Obfuscated? Click here for steps to deobfuscate proguard.
                    </a>
                  </i>
                </div>
                <pre
                  className="div-3-div-6-table text-wrap"
                  style={{ padding: "20px" }}
                >
                  {stackTrace}
                </pre>
              </div>
            ) : (
              <div></div>
            )}
            {activityTrail && (
              <div>
                <p className="div-3-div-6-p div-1-label">ACTIVITY TRAIL</p>
                <div className="table-container div-3-div-6-table">
                  <div className="table-row">
                    <div className="table-cell div-3-div-6-p activity-hide">ACTIVITY</div>
                    <div className="table-cell div-3-div-6-p activity-hide">START TIME</div>
                    <div className="table-cell div-3-div-6-p activity-hide">FREE MEMORY</div>
                  </div>
                  {JSON.parse(activityTrail).map((activity, index) => (
                    <div key={index} className="table-row">
                      <div className="table-cell">
                        <div className="activity-label div-1-label">ACTIVITY:</div><br></br>{activity.screenName + ":" + activity.activity}
                      </div>
                      <div className="table-cell">
                        <div className="activity-label div-1-label">START TIME:</div><br></br>{formatDate1(activity.time)}
                      </div>
                      <div className="table-cell"><div className="activity-label div-1-label">FREE MEMORY:</div><br></br>{activityPercentage(activity.maxMemory,activity.totalMemory,activity.freeMemory)}%</div>
                    <hr className="activity-label"></hr>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {device.length != 0 && (
              <div>
                <p className="div-3-div-6-p div-1-label">DEVICE</p>
                <div className="div-3-div-6-table key-value-groups">
                  {Object.entries(device)
                    .filter(([key, value]) => value !== null)
                    .map(([key, value]) => (
                      <div
                        className="key-value-group"
                        key={key}
                        style={{ margin: "5px" }}
                      >
                        <div className="div-3-div-6-p div-1-label">{convertText(key)}</div>
                        <pre className="text-wrap">{convertValue(value)}</pre>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {deviceState.length != 0 && (
              <div>
                <p className="div-3-div-6-p  div-1-label">DEVICE STATE</p>
                <div className="div-3-div-6-table key-value-groups">
                  {Object.entries(deviceState)
                    .filter(([key, value]) => value !== null)
                    .map(([key, value]) => (
                      <div
                        className="key-value-group"
                        key={key}
                        style={{ margin: "5px" }}
                      >
                        <div className="div-3-div-6-p div-1-label">{convertText(key)}</div>
                        <pre className="text-wrap">{convertValue(value)}</pre>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        )}
        <div className="div-2-class">
          {counter === 2 && (
            <button onClick={decrementCounter}>⇚ Previous</button>
          )}
          <button onClick={incrementCounter} className="next-button">
            Next ⇛
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketInfo;
