import React, { useEffect } from "react";
import "./Navbar.css";
import logo from "./logo.png";
import { Link } from "react-router-dom";

function Navbar() {
  // window.addEventListener("message", function (event) {
  //   if (event.origin === "https://finotes.com") {
  //     var data = event.data;
  //     if (data.action === "getHostname") {
  //       var parentHostname = window.location.hostname;
  //       console.log(parentHostname);
  //     }
  //   }
  // });
  // const currentURL = window.parent.location.hostname;
  return (
    <div className="main-nav">
      <div className="nav-div-1">
      <Link to="/">
            <img
              src={logo}
              className="logo-nav"
              style={{ marginRight: "5px" }}
            />
          </Link>

        <a href="https://finotes.com" class="link" target="_blank">Finotes</a>

        <Link to="/" className="link">
          Tickets
        </Link>
      </div>
      <div className="nav-div-2">
        {/* {currentURL === "demo.finotes.com" && (
          <a
            href="https://finotes.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Go to Finotes.com
          </a>
        )} */}
      </div>
    </div>
  );
}

export default Navbar;
