import AppList from "./components/AppList/AppList.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TicketInfo from "./components/TicketInfo/TicketInfo.jsx";
import "./App.css";

//new comment
function App() {
  return (
    <div className="App" style={{margin:'0'}}>
      <Router>
        <Routes>
          <Route path="/" element={<AppList />} />
          <Route path="/:ticketId" element={<TicketInfo />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
