import React, { useState, useEffect } from "react";
import appData from "./appList.json";
import radixSort from "./sortData";
import { Link } from "react-router-dom";
import "./AppList.css";
import Navbar from "../Navbar/Navbar";

function AppList() {
  const [app, setApp] = useState(localStorage.getItem("app") || "");
  const [platform, setPlatform] = useState(
    localStorage.getItem("platform") || ""
  );
  const [affectedDevices, setAffectedDevices] = useState(false);
  const [lastReportedTime, setLastReportedTime] = useState(true);
  const [version, setVersion] = useState(localStorage.getItem("version") || "");
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [platformData, setPlatformData] = useState([]);
  const [versionData, setVersionData] = useState([]);
  const [ticketData, setTicketData] = useState(null);
  const [sorting, setSorting] = useState({
    field: "lastReportedTime",
    order: "asc",
  });
  const bugs = [
    "CUSTOM ISSUE",
    "FUNCTION RETURNS NULL",
    "FUNCTION RETURNS VALUE OUTSIDE RANGE",
    "FUNCTION RETURNS WRONG VALUE",
    "FUNCTION DELAYED EXECUTION",
    "SCREEN LOADING DELAYED",
    "ASYNCHRONOUS METHOD NOT CALLED",
    "ASYNCHRONOUS METHOD CALL DELAYED",
    "LOW MEMORY WARNING",
    "EXCEPTION WITHIN OBSERVED FUNCTION",
    "GENERIC EXCEPTION",
    "CUSTOM EXCEPTION LOGGING",
    "HTTP WRONG STATUS CODE",
    "NETWORK CALL FAILED",
    "HTTP CALL DELAYED",
    "TRIM MEMORY",
    "HIGH MEMORY USAGE",
    "NETWORK ANOMALY",
    "UI THREAD BLOCK",
    "MEMORY SPIKE",
    "MEMORY LEAK",
    "NSERROR",
    "FROZEN FRAME",
    "SLOW FRAME",
    "ANR",
    "APP HANG",
    "APP EXIT CAUSED BY ANR",
    "MEMORY LEAK IN DART CODE",
    "APP COLD START UP",
    "APP WARM START UP",
    "APP HOT START UP",
    "APP COLD START UP"
  ];
  function handleChangeApp(e) {
    const newApp = e.target.value;
    setApp(newApp);
    localStorage.setItem("app", newApp);
    localStorage.removeItem("platform");
    localStorage.removeItem("version");
    setVersion("");
    setShowAnalytics(false);
  }
  useEffect(() => {
    const file = app + ".json";
    fetch(file)
      .then((response) => response.json())
      .then((data) => setPlatformData(data))
      .catch((error) => setPlatformData([]));
    setTicketData([]);
  }, [app]);
  function handleChangePlatform(e) {
    const newPlatform = e.target.value;
    setPlatform(newPlatform);
    localStorage.setItem("platform", newPlatform);
    localStorage.removeItem("version");
    setShowAnalytics(false);
  }
  useEffect(() => {
    const file = platform + ".json";
    fetch(file)
      .then((response) => response.json())
      .then((data) => setVersionData(data))
      .catch((error) => setVersionData([]));
  }, [platform]);
  function handleChangeVersion(e) {
    const newVersion = e.target.value;
    setShowAnalytics(false);
    setVersion(newVersion);
    localStorage.setItem("version", newVersion);
  }
  useEffect(() => {
    const file = version + ".json";
    fetch(file)
      .then((response) => response.json())
      .then((data) => setTicketData(data))
      .catch((error) => setTicketData([]));
  }, [version]);
  const handleSort = (field) => {
    if (field === "deviceCount") {
      setAffectedDevices(!affectedDevices);
      setLastReportedTime(false);
    } else if (field === "lastReportedTime") {
      setLastReportedTime(!lastReportedTime);
      setAffectedDevices(false);
    }
    const newOrder =
      sorting.field === field && sorting.order === "asc" ? "desc" : "asc";
    setSorting({ field, order: newOrder });

    const sortedData = radixSort(ticketData.slice(), field, newOrder);

    setTicketData(sortedData.reverse());
  };
  function handleTicketList(id, severity) {
    localStorage.setItem("ticketId", id);
    localStorage.setItem("severity", severity);
  }
  function handleAnalytics() {
    setShowAnalytics(!showAnalytics);
  }
  useEffect(() => {
    const version = localStorage.getItem("version");
    if (version !== null) {
      fetchData();
    }
  }, [showAnalytics, version]);

  const fetchData = async () => {
    try {
      const file = version + "-analytics.json";
      const response = await fetch(file);
      const data = await response.json();
      setAnalytics(data);
      const sortedArray = radixSort(analytics.typeSummaryList, "value");
      setSortedData(sortedArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const count = analytics ? analytics.installationCount : "";
  function formatDate(epochTime) {
    const date = new Date(epochTime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  function getSeverityClass(severity) {
    if (severity === 0) {
      return "severity-0";
    } else if (severity === 1) {
      return "severity-1";
    } else {
      return "severity-2";
    }
  }
  return (
    <div>
      <Navbar/>
      <div className="whole">
        <div className="main"> 
          <label className="label">
            App:
            <select
              value={app}
              onChange={handleChangeApp}
              className="select-label"
            >
              <option value="">Select App</option>
              {appData.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </label>
          <label className="label">
            Platform:
            <select
              value={platform}
              onChange={handleChangePlatform}
              className="select-label"
            >
              <option value="">Select Platform</option>
              {platformData.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </label>
          <label className="label">
            Version:
            <select
              value={version}
              onChange={handleChangeVersion}
              className="select-label"
            >
              <option value="">Select Version</option>
              {versionData.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="analy-main">
          <div className="analytics-div">
            <div>
              <button
                onClick={handleAnalytics}
                className="analytics-btn tooltip-trigger pointer"
                title=""
              >
                {showAnalytics ? "Hide Analytics ⇑" : "Show Analytics ⇓ "}
                <warn>ⓘ</warn>
              </button>
            </div>
            {showAnalytics && (
              <div className="analy-div">
                <div id="installation-count">
                  <strong>Total installations:</strong>
                  {count}
                </div>
                <div className="percentage-div">
                  <h2 className="percentage-text">
                    Percentage of installations affected
                  </h2>
                  {sortedData.map((item, index) => (
                    <div key={index} className="graph-div2">
                      <div className="graph-label">{bugs[item.name - 1]}</div>
                      <div
                        className="graph-graph"
                        style={{
                          width: `${
                            (item.value * 100) / analytics.installationCount
                          }%`,
                          textAlign: "right",
                          height: "25px",
                          maxWidth: "100vw",
                          backgroundImage:
                            "linear-gradient(to right, #f9f6ed, #ffa700)",
                        }}
                      >
                        {(
                          (item.value * 100) /
                          analytics.installationCount
                        ).toFixed(2)}
                        %
                      </div>
                      <div style={{ marginLeft: "10px" }}></div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="table-div">
          {ticketData && ticketData.length > 0 && (
            <div className="table">
              <div>
                <div className="header-row">
                  <div className="ticket-cell cell unsort ticket">Ticket</div>
                  <div
                    onClick={() =>
                      handleSort("deviceCount", (obj) => obj.deviceCount)
                    }
                    className="cell tooltip-trigger pointer"
                    id="my-tooltip-inline"
                  >
                    Affected Devices <warn>ⓘ</warn> {affectedDevices ? "⇓" : ""}
                  </div>
                  <div className="cell unsort">Occurrences</div>
                  <div
                    onClick={() =>
                      handleSort(
                        "lastReportedTime",
                        (obj) => obj.lastReportedTime
                      )
                    }
                    className="cell pointer"
                  >
                    Last Reported Time {lastReportedTime ? "⇓" : ""}
                  </div>
                </div>
              </div>
              <div>
                {ticketData &&
                  ticketData.map((item) => (
                    <div key={item.id}>
                      <Link
                        to={`${item.id}`}
                        onClick={() => handleTicketList(item.id, item.severity)}
                        className="row link-row"
                      >
                        <div className={getSeverityClass(item.severity)}>
                          ☉ &nbsp;
                        </div>
                        <div className=" ticket-cell cell">
                          {item.description} <span className="red-label">{(item.sdk === 1 ? "debug": "")}</span>
                        </div>
                        <div className="cell">{item.deviceCount}</div>
                        <div className="cell">{item.occurrenceCount}</div>
                        <div className="cell">
                          {formatDate(item.lastReportedTime)}
                        </div>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppList;
