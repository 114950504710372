import React, { useEffect, useState } from "react";
import './HorizontalBarChart.css';

const HorizontalBarChart = ({ osSummary, deviceSummary, countrySummary }) => {
  const [sortedOsSummary, setSortedOsSummary] = useState([]);
  const [sortedDeviceSummary, setSortedDeviceSummary] = useState([]);
  const [sortedCountrySummary, setSortedCountrySummary] = useState([]);

  const radixSortSummary = (summary) => {
    const max = Math.max(...summary.map((item) => item.value));
    const maxLength = Math.floor(Math.log10(max)) + 1;

    for (let i = 0; i < maxLength; i++) {
      const buckets = Array.from({ length: 10 }, () => []);

      for (let j = 0; j < summary.length; j++) {
        const digit = Math.floor(summary[j].value / Math.pow(10, i)) % 10;
        buckets[digit].push(summary[j]);
      }

      summary = buckets.flat();
    }

    return summary.reverse();
  };
  function getBarClass(title){
    if(title==="OS VERSIONS"){
      return 'graph-item os-version-class';
    }else if(title==="DEVICES"){
      return 'graph-item device-class';
    }else{
      return 'graph-item country-class';
    }
  }
  useEffect(() => {
    if (osSummary) {
      const sortedOs = radixSortSummary(osSummary);
      setSortedOsSummary(sortedOs);
    }

    if (deviceSummary) {
      const sortedDevice = radixSortSummary(deviceSummary);
      setSortedDeviceSummary(sortedDevice);
    }

    if (countrySummary) {
      const sortedCountry = radixSortSummary(countrySummary);
      setSortedCountrySummary(sortedCountry);
    }
  }, [osSummary, deviceSummary, countrySummary]);

  const renderBarGraph = (summary, title) => (
    <div className="graph-div" style={{display:'flex',flexDirection:'column'}}>
      <div style={{width:'auto'}}>
        <h3 className="graph-title">{title}</h3>
        <div className="bar-graph">
          {summary.map((item, index) => (
            <div >
              <div
                key={index}
                className={getBarClass(title)}
                style={{
                  width: `${item.value * 20}px`,
                  height: "20px",
                }}
              ></div>
              <div className="graph-label">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ marginBottom: '10px'}}>
      {renderBarGraph(sortedOsSummary, "OS VERSIONS")}
      {renderBarGraph(sortedDeviceSummary, "DEVICES")}
      {renderBarGraph(sortedCountrySummary, "COUNTRIES")}
    </div>
  );
};

export default HorizontalBarChart;
