import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if (window.location.hostname === "demo.finotes.com") {
  var gt = "AW-689914167";
  var g = document.createElement("script");
  g.type = "text/javascript";
  g.async = true;
  g.src = "//www.googletagmanager.com/gtag/js?id=" + gt;
  var i = document.getElementsByTagName("script")[0];
  i.parentNode.insertBefore(g, i);
  window.dataLayer = window.dataLayer || [];
  window.gtag = window.gtag || function () { window.dataLayer.push(arguments); };
  window.gtag("js", new Date());
  window.gtag("config", gt);
}
