const radixSort = (arr, field, order) => {
  const maxDigitCount = Math.max(...arr.map(obj => obj[field].toString().length));

  for (let k = 0; k < maxDigitCount; k++) {
    const buckets = Array.from({ length: 10 }, () => []);

    for (let i = 0; i < arr.length; i++) {
      const digit = getDigit(arr[i][field], k);
      buckets[digit].push(arr[i]);
    }

    arr = [].concat(...buckets);
  }

  return order === 'asc' ? arr : arr.reverse();
};

const getDigit = (num, k) => {
  return Math.floor(Math.abs(num) / Math.pow(10, k)) % 10;
};

export default radixSort;
